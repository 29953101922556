import "./MainNav.css";
import { Layout, Menu, Button, Typography } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import firebaseAPI from "../api/firebase";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
const { Header } = Layout;

function currentRouteIndex(path) {
  switch (path) {
    case "/":
      return 1;
    case "/actions_log":
      return 2;
  }
}

export default function MainNav() {
  const location = useLocation();
  const history = useHistory();

  const [selectedItem, setSelectedItem] = useState(
    currentRouteIndex(location.pathname)
  );

  function navigate(index) {
    switch (index) {
      case 1:
        setSelectedItem(index);
        history.push("/");

        break;
      case 2:
        setSelectedItem(index);
        history.push("/actions_log");
        break;
    }
  }

  return (
    <Header>
      <Menu
      dashed={false}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={selectedItem.toString()}
      >

        
        <Menu.Item key="1" onClick={() => navigate(1)}>
          Reports
        </Menu.Item>
{/* 
        <Menu.Item key="2" onClick={() => navigate(2)}>
          Actions Log
        </Menu.Item> */}
        
        <div style={{ position:'fixed', right:50 }}>
          <Typography.Text style={{color:'rgba(255, 255, 255, 0.65)', paddingRight:15}}>{firebaseAPI.getCurrentUser().email}</Typography.Text>
          <Button
            onClick={() => firebaseAPI.userSignOut()}
            type="primary"
            ghost="true"
            shape="round"
            icon={<LogoutOutlined />}
            size={18}
            className="tableButton"
          >
            Log Out
          </Button>

        </div>
       
      </Menu>
    </Header>
  );
}
