import moment from "moment";
import "datejs";

const createPaymentPeriods = () => {
  let start = "2018-01-05";
  let end = "2018-01-13";

  let dateArry = [];
  let newExtraWeek = false;

  while (new Date(start) < new Date()) {
    if (newExtraWeek) {
      start = Date.parse(start)
        .next()
        .saturday()
        .next()
        .saturday()
        .next()
        .saturday()
        .toString("yyyy-MM-dd");
      newExtraWeek = false;
    } else {
      start = Date.parse(start)
        .next()
        .saturday()
        .next()
        .saturday()
        .toString("yyyy-MM-dd");
    }
    if (start === "2020-06-27" || newExtraWeek) {
      newExtraWeek = true;
      end = Date.parse(start)
        .next()
        .friday()
        .next()
        .friday()
        .next()
        .friday()
        .toString("yyyy-MM-dd");
    } else if (start === "2020-08-29" || newExtraWeek) {
      newExtraWeek = true;
      end = Date.parse(start)
        .next()
        .friday()
        .next()
        .friday()
        .next()
        .friday()
        .toString("yyyy-MM-dd");
    } else {
      end = Date.parse(start)
        .next()
        .friday()
        .next()
        .friday()
        .toString("yyyy-MM-dd");
    }
    let lenguage = "en";

    const startDisplay = formateDatePayment(start, lenguage);
    const endDisplay = formateDatePayment(end, lenguage);

    dateArry.push({
      start: {
        value: start,
        display: startDisplay,
      },
      end: {
        value: end,
        display: endDisplay,
      },
    });
  }

  dateArry = dateArry.splice(dateArry.length - 30);
  dateArry.pop();
  dateArry.reverse();

  return dateArry;
};

const formateDatePayment = (dateFormat, lenguage) => {
  let localdateFormat;
  if (lenguage === "es") {
    moment.locale("es");
    localdateFormat = moment(dateFormat).format("D MMM YYYY");
  } else if (lenguage === "en") {
    moment.locale("en");
    localdateFormat = moment(dateFormat).format("MMM D YYYY");
  } else {
    moment.locale("es");
    localdateFormat = moment(dateFormat).format("D MMM YYYY");
  }
  return localdateFormat;
};

const getPaymentDate = (toDate) => {
  if (toDate) {
    return moment(Date.parse(toDate).next().friday().next().thursday()).format(
      "ll"
    );
  } else {
    return null;
  }
};
const getPaymentDateCountdown = (toDate) => {
  if (toDate) {
    return moment(Date.parse(toDate).next().friday().next().thursday())
      .add(12, "hours")
      .calendar();
  } else {
    return null;
  }
};
export default {
  createPaymentPeriods,
  getPaymentDate,
  getPaymentDateCountdown,
};
