import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import firebaseConfig from "./config/firebase";

import LoginScreen from "./screens/LoginScreen";
import LoadingScreen from "./screens/LoadingScreen";
import MainScreen from "./screens/MainScreen";
import ActionsLogScreen from "./screens/ActionsLogScreen";

import { initializeApp } from "firebase/app";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import "./App.css";

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();

export default function App() {

  const [user, setUser] = useState({});
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  useEffect(() => {
    const unregister = onAuthStateChanged(auth, (usr) => {
      if (usr) {
        setUser(user);
        setUserAuthenticated(true);
        setFirebaseLoaded(true);
        // console.log(usr);
        // console.log('User is signed in')
      } else {
        setUserAuthenticated(false);
        setFirebaseLoaded(true);
        // console.log("User signed out");
      }
    });
    return () => unregister()
  }, []);

  return (
    <Router>
      {firebaseLoaded === false ? (
        <Switch>
          <LoadingScreen />
        </Switch>
      ) : (
        <Switch>
          <PrivateRoute
            path="/login"
            userAuthenticated={!userAuthenticated}
            component={LoginScreen}
            redirect="/"
          />
          <PrivateRoute
            exact
            path="/"
            userAuthenticated={userAuthenticated}
            component={MainScreen}
            redirect="/login"
          />
          <PrivateRoute
            exact
            path="/actions_log"
            userAuthenticated={userAuthenticated}
            component={ActionsLogScreen}
            redirect="/login"
          />

          <Route path="/">
            <MainScreen />
          </Route>
        </Switch>
      )}
    </Router>
  );
}

function PrivateRoute({
  component: Component,
  userAuthenticated,
  redirect,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        userAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
