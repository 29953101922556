import React from "react";
import "./LoadingScreen.css";
import { Spin, Space, Col, Row } from "antd";

export default function LoadingScreen() {
  return (
    <div className="loadingScreen">
      <Row justify="center">
        <Spin size="large" />
      </Row>
    </div>
  );
};