// const PDFDocument = require('pdfkit');
// const blobStream  = require('blob-stream');

function createInvoice(obj) {
  //   console.log(obj)
    // // create a document the same way as above
    // const doc = new PDFDocument;

    // // pipe the document to a blob
    // const stream = doc.pipe(blobStream());

    // // add your content to the document here, as usual

    // // get a blob when you're done
    // doc.end();
    // stream.on('finish', function() {
    // // get a blob you can do whatever you like with
    // const blob = stream.toBlob('application/pdf');
    // // downloadFile(blob, 'download.pdf');
    // // or get a blob URL for display in the browser
    // const url = stream.toBlobURL('application/pdf');
    // // iframe.src = url;
    // });
};

// const downloadFile = (blob, fileName) => {
//     const link = document.createElement('a');
//     // create a blobURI pointing to our Blob
//     link.href = URL.createObjectURL(blob);
//     link.download = fileName;
//     // some browser needs the anchor to be in the doc
//     document.body.append(link);
//     link.click();
//     link.remove();
//     // in case the Blob uses a lot of memory
//     setTimeout(() => URL.revokeObjectURL(link.href), 7000);
//   };

export default {
    createInvoice
};