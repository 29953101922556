var firebaseConfig = {
  apiKey: "AIzaSyAkOzeRKNXXhwi5znncbuSHNgV6jIreyfs",
  authDomain: "instagram-bd374.firebaseapp.com",
  projectId: "instagram-bd374",
  storageBucket: "instagram-bd374.appspot.com",
  messagingSenderId: "307331977637",
  appId: "1:307331977637:web:93182637c85b10bfdb184c",
  measurementId: "G-T1PP4T7DEL",
  databaseURL: "https://instagram-bd374.firebaseio.com",
};

export default firebaseConfig;
