import "./MainScreen.css";
import { List, Typography, Layout, Button, Tag } from "antd";
import { MailOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import firebaseAPI from "../api/firebase";
import MainNav from "../components/MainNav";

const { Content, Footer } = Layout;

async function loadLogs(setLog) {
  const logs = await firebaseAPI.getLogs(100);
  // console.log(logs)
  setLog(logs);
}

function Icon({ type }) {
  switch (type) {
    case "email_sent":
      return <Button type="primary" shape="circle" icon={<MailOutlined />} />;
  }
}

function ActionText({ type, id, hostname, name }) {
  switch (type) {
    case "email_sent":
      return (
        <>
          <Typography.Text>
            Email send to{" "}
            <b>
              <i>{name}</i>
            </b>
          </Typography.Text>
          <br />
        </>
      );
  }
}
function SourceText({ type, hostname, email }) {
  switch (type) {
    case "email_sent":
      return (
        <>
          {hostname ? (
            <>
              <Typography.Text>
                <b>Site:</b> {hostname}
              </Typography.Text>
              <br />
            </>
          ) : null}

          <Typography.Text>
            <b>Email:</b> {email}
          </Typography.Text>
          <br />
        </>
      );
  }
}

function RangeText({ dateRange }) {
  return (
    <div>
      <Typography.Text>
        <b>From: </b>{" "}
        <Tag color="default">
          {new Date(dateRange.from.toDate()).toLocaleDateString("en-US")}
        </Tag>{" "}
      </Typography.Text>
      <Typography.Text>
        <b>To: </b>{" "}
        <Tag color="default">
          {new Date(dateRange.to.toDate()).toLocaleDateString("en-US")}
        </Tag>{" "}
      </Typography.Text>
    </div>
  );
}

function ListDescription({ type, id, hostname, name, dateRange, email }) {
  // console.log(hostname, 'HOSTNAME')
  return (
    <>
      <ActionText type={type} id={id} hostname={hostname} name={name} />
      <SourceText type={type} hostname={hostname} name={name} email={email} />
      <RangeText dateRange={dateRange} />
    </>
  );
}
function Timestamp({ timestamp }) {
  return (
    <Typography.Text>
      {new Date(timestamp.toDate()).toLocaleTimeString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
      {/* {new Date(timestamp.toDate()).to} */}
    </Typography.Text>
  );
}
export default function ActionsLogScreen() {
  const [log, setLog] = useState([]);

  useEffect(() => {
    loadLogs(setLog);
  }, []);

  return (
    <Layout className="layout">
      <MainNav />

      <Content style={{ padding: "0 50px", marginTop: "85px" }}>
        <div className="site-layout-content">
          <List
            header={
              <div>
                <b>Actions Log</b>
              </div>
            }
            pagination={true}
            style={{ borderWidth: 0, paddingBottom:5 }}
            bordered
            dataSource={log}
            renderItem={(item) => (
              <List.Item >
                <List.Item.Meta
                  avatar={<Icon type={item.type} />}
                  title={<Timestamp timestamp={item.timestamp} />}
                  description={
                    <ListDescription
                      type={item.type}
                      name={item.name}
                      id={item.id}
                      hostname={item.hostname}
                      email={item.email}
                      dateRange={item.dateRange}
                    />
                  }
                  
                />
              </List.Item>
              // <List.Item>
              //     <Icon type={item.type}/><br/><Timestamp timestamp={item.timestamp}/> <br/> <ActionText type={item.type} id={item.id}/>
              // </List.Item>
            )}
          />
        </div>
      </Content>

      {/* <Footer className="footer">
        Social Tech ©{new Date().getFullYear()}
      </Footer> */}
    </Layout>
  );
}
