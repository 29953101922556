import  { getAuth, signOut, currentUser } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  getDoc,
  doc,
  FieldValue,
  updateDoc,
  deleteDoc,
  arrayUnion,
  query,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";

import moment from 'moment';

function userSignOut() {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    //   console.log("Logged out");
    })
    .catch((error) => {
    //   console.log(error);
      // An error happened.
    });
}

function getCurrentUser() {
  const auth = getAuth();
  const user = auth.currentUser;
//   console.log(user, 'user')
  return user; 
}

//INSTAGRAM ANALYTICS PRO
async function getSales() {
  const testUsernames = [ "dlrdhvs","testincfor", "ensaladatest", "bp13alfa", "linosuarez", "tester123", "cornorjohn91", "laporfavore"]
  var afterThisDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
  console.log(afterThisDate, 'afterThisDate')
  const db = getFirestore();
  // const q = query(collection(db, "subscription"),  orderBy("initial_date", "desc"));
  const q = query(collection(db, "subscription"),  where("initial_date", ">",afterThisDate), orderBy("initial_date", "desc"));
  const docSnap = await getDocs(q);
  const arry = [];
  console.log('done')
  docSnap.forEach((doc) => {
    if (!testUsernames.includes(doc.data().username)) {
      arry.push(doc.data());
    }
  });
  return arry;
};


async function createEmailRecord(id_model, from, to) {
  const db = getFirestore();
  const pathSegment = from + "-" + to;
  const dotNotationRoute = `emailsSent.${id_model}`;

  try {
    await updateDoc(doc(db, "emails", pathSegment), {
      [dotNotationRoute]: true,
    });
  } catch (e) {
    // console.error("Error adding document: ", e);
    // console.error(JSON.stringify(e));
    if (e.code === "not-found") {
      await setDoc(doc(db, "emails", pathSegment), {
        from: new Date(from),
        to: new Date(to),
        emailsSent: {
          [id_model]: true,
        },
      });
    }
  }
};


async function getEmailRecords(from, to) {
  const db = getFirestore();
  const pathSegment = from + "-" + to;

  const docRef = doc(db, "emails", pathSegment);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().emailsSent;
  } else {
    return {};
  }
};

async function paymentSent(id, from, to, state) {
  // console.log(id, from, to, state)
  //state true or false
  const db = getFirestore();
  const pathSegment = from + "-" + to;
  const dotNotationRoute = `paymentSent.${id}`;

  try {
    await updateDoc(doc(db, "payments", pathSegment), {
      [dotNotationRoute]: state,
    });
  } catch (e) {
    // console.error("Error adding document: ", e);
    // console.error(JSON.stringify(e));
    if (e.code === "not-found") {
      await setDoc(doc(db, "payments", pathSegment), {
        from: new Date(from),
        to: new Date(to),
        paymentSent: {
          [id]: state,
        },
      });
    }
  }
};
async function getPaymentsSent(from, to) {
  const db = getFirestore();
  const pathSegment = from + "-" + to;

  const docRef = doc(db, "payments", pathSegment);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log(docSnap.data(),' hohoh')
    return docSnap.data().paymentSent;
  } else {
    return {};
  }
}

async function updateNotes(model_id, text, client = true) {
  const notesPath = client ? "notes" : 'affiliate_notes';

  const db = getFirestore();
  const pathSegment = model_id.toString();

  try {
    if (text === "") {
      return await deleteDoc(doc(db, notesPath, pathSegment));
    }

    return await updateDoc(doc(db, notesPath, pathSegment), {
      notes: text,
    });
  } catch (e) {
    if (e.code === "not-found") {
      await setDoc(doc(db, notesPath, pathSegment), {
        notes: text,
      });
    }
  }
}

async function getClientsWithNotes() {
  const db = getFirestore();
  const q = query(collection(db, "notes"));
  const docSnap = await getDocs(q);
  const obj = {};
  docSnap.forEach((doc) => {
    obj[doc.id] = true;
  });
  return obj;
};
async function getAffiliatesNotes() {
  const db = getFirestore();
  const q = query(collection(db, "affiliate_notes"));
  const docSnap = await getDocs(q);
  const obj = {};
  docSnap.forEach((doc) => {
    obj[doc.id] = true;
  });
  return obj;
};

async function getNotes(id = null, client = true) {

  const notesPath = client ? 'notes' : 'affiliate_notes'
  const db = getFirestore();

  const pathSegment = id.toString();


  const docRef = doc(db, notesPath, pathSegment);
  const docSnap = await getDoc(docRef);

  
  if (docSnap.exists()) {
    return docSnap.data().notes;
  } else {
    return "";
  }
}

async function addLog(type, clientObj, dateRange) {
  const { id_model, id, hostname, name, email } = clientObj;
  const db = getFirestore();

  try {
    await addDoc(collection(db, "logs"), {
      timestamp: new Date(),
      type: type,
      id: id_model || id,
      hostname: hostname || null,
      email: email,
      name: name,
      dateRange: {
        from: new Date(dateRange.from),
        to: new Date(dateRange.to),
      },
    });
  } catch (e) {
  //   console.log(e);
  }
}

async function getLogs(count = 100) {
  const db = getFirestore();

  const q = query(
    collection(db, "logs"),
    orderBy("timestamp", "desc"),
    limit(count)
  );

  const docSnap = await getDocs(q);
  const arry = [];
  docSnap.forEach((doc) => {
    arry.push(doc.data());
  });
  return arry;
};

export default {
  userSignOut,
  getCurrentUser,

  createEmailRecord,
  getEmailRecords,

  updateNotes,
  getNotes,

  addLog,
  getLogs,

  getClientsWithNotes,
  getAffiliatesNotes,

  paymentSent,
  getPaymentsSent,


  getSales
};