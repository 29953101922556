import "./MainScreen.css";
import {
  Layout,
  DatePicker,
  Row,
  Col,
  Button,
  Form,
  Select,
  Tabs,
  Card,
  AutoComplete,
  Typography,
  Divider,
  Statistic,
  // Routes
} from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import ReportsTable from "../components/ReportsTable";
import ReferralsTable from "../components/ReferralsTable";
import socialtechAPI from "../api/socialtechAPI";
import firebaseAPI from "../api/firebase";

import { SalesChart } from "../components/SalesChart";
import MainNav from "../components/MainNav";

import DateRangePicker from "../components/DateRangePicker";
import extra from "../extra";

const { Option } = Select;
const { TabPane } = Tabs;

// const { RangePicker } = DatePicker;

const { Content, Footer } = Layout;

function formatCurrency(amount) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

async function getNotes(setNotes) {
  const clientNotes = await firebaseAPI.getClientsWithNotes();

  setNotes(clientNotes);
}

async function getAffiliatesNotes(setAffiliateNotes) {
  const affiliateNotes = await firebaseAPI.getAffiliatesNotes();

  setAffiliateNotes(affiliateNotes);
}

export default function MainScreen() {
  const [clientEarnings, setClientEarnings] = useState([]);
  const [clientEarningsCopy, setClientEarningsCopy] = useState([]);
  const [filterRecords, setFilterRecords] = useState([]);
  const [emailRecords, setEmailRecords] = useState({});
  const [paymentSent, setPaymentSent] = useState({});
  const [notes, setNotes] = useState({});
  const [affiliateNotes, setAffiliateNotes] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentPeriods, setPaymentPeriods] = useState([]);
  const [filterHostnameText, setFilterHostnameText] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [dateSelectIndex, setDateSelectIndex] = useState(null);


  const [sales, setSales] = useState(null);
  const [todaySales, setTodaySales] = useState(null);
  const [yesterdaySales, setYesterdaySales] = useState(null);
  const [lastWeekSales, setLastWeekSales] = useState(null);

  function setPeriodicSales(sales) {
    const today = moment().format("YYYY-MM-DD");
    const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
    const lastWeek = moment().subtract(7, "days").format("YYYY-MM-DD");

    let todaySales = 0;
    let yesterdaySales = 0;
    let lastWeekSales = 0;


    

    sales.forEach(sale => {
      if (moment(sales.initial_date).isSame(today, 'day')) {
        todaySales += parseFloat(sale.price);
        console.log(sale.price, 'TODAY')
      }
      if (moment(sales.initial_date).isSame(yesterday, 'day')) {
        yesterdaySales += parseFloat(sale.price);
      }
      if (moment(sales.initial_date).isSameOrAfter(lastWeek, 'day')) {
        lastWeekSales += parseFloat(sale.price);
      }
      
    })

    console.log(todaySales, 'TODAY SALES')
  };
  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}
function addHours(numOfHours, date = new Date()) {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
}

  useEffect(async () => {
    // const paymentPeriods = extra.createPaymentPeriods();

    // setPaymentPeriods(paymentPeriods);

    // getNotes(setNotes);
    // getAffiliatesNotes(setAffiliateNotes);

    let sales = await firebaseAPI.getSales();

    sales.forEach(salesData => {
      //  if (salesData.attribution_data) {
      //    console.log(salesData.attribution_data, 'attribution_data')
      //    alert('FOUND')
      //  }
      //  if (salesData.iAd_attribution_data) {
      //    console.log(salesData.iAd_attribution_data, 'iAd_attribution_data')
      //    alert('FOUND')
      //  }
      //  if (salesData.adServices_attribution_data) {
      //    console.log(salesData.adServices_attribution_data, 'adServices_attribution_data')
      //    alert('FOUND')
      //  }
    });





    sales = sales.map((sale => {
      sale.initial_date = addHours(-7, new Date(sale.initial_date));
      if (!!sales.install_date) {
        sale.install_date = addHours(-7, new Date(sale.install_date));
      }
      return sale
    }));
    
    sales = sales.filter(({ subscription_id, install_date }) => {
      return subscription_id.length < 35;
    });
    setSales(sales);
    // setPeriodicSales(sales);
  }, []);

  function onChangeSelect(index, options) {
    setDateSelectIndex(index);
    if (options === undefined) return;
    console.log(options.period);
    setFromDate(options.period.start.value);
    setToDate(options.period.end.value);

    getEarnings(options.period.start.value, options.period.end.value);
  }
  
  function clearTableValues() {
    setClientEarnings([]);
    setClientEarningsCopy([]);
    setFilterRecords([]);
    setFilterHostnameText("");
  }

  function clearDates() {
    setFromDate(null);
    setToDate(null);
    setDateSelectIndex(null);
  }

  async function getEarnings(from = fromDate, to = toDate) {
    console.log(from, "from");
    console.log(to, "to");
    setLoading(true);
    clearTableValues();

    const key = "ccd4af8f-ff63-4db1-986f-d2f9058be60e";
    const api = new socialtechAPI(key);

    const res = await api.getEarnings(from, to);
    const records = await firebaseAPI.getEmailRecords(from, to);

    const resPaymentsSent = await firebaseAPI.getPaymentsSent(from, to);

    const sites = res.sites.map((site) => {
      return { value: site.hostname };
    });

    setEmailRecords(records);
    setClientEarnings(res);
    setClientEarningsCopy(res);
    setFilterRecords(sites);
    setPaymentSent(resPaymentsSent);

    setLoading(false);
  }

  const onSearch = (searchText) => {
    setFilterHostnameText(searchText.toLowerCase());
    if (clientEarningsCopy.length === 0) {
      setClientEarningsCopy(clientEarnings);
    }

    if (clientEarningsCopy.sites) {
      const filter = clientEarningsCopy.sites.filter((res) => {
        return res.hostname.startsWith(searchText);
      });
      const filterSites = filter.map((res) => {
        return { value: res.hostname };
      });
      setClientEarnings({ ...clientEarningsCopy, sites: filter });
      setFilterRecords(filterSites);
    }
  };

  function advanceDatePickerOnChange(checked) {
    setAdvanceSearch(checked);
    clearTableValues();
    clearDates();
    // console.log(`switch to ${checked}`);
  }

  return (
    <Layout className="layout">
      <MainNav />

      <Content style={{ padding: "0 50px", marginTop: "85px" }}>
        {/* <Row>
          <Card style={{ borderRadius: 20, marginLeft: 20 }}>
            <Col span="24" style={{ minWidth: 200 }}>
              <div>
                <Statistic
                  title="Total Payouts"
                  value={clientEarnings?.totalPayouts?.text}
                />
              </div>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <div>
                <Typography.Text>
                  Client Payout{" "}
                  <Typography.Text code>
                    {clientEarnings?.totalTalentsPayed?.text}
                  </Typography.Text>
                </Typography.Text>
              </div>
              <div>
                <Typography.Text>
                  Referrals Payout{" "}
                  <Typography.Text code>
                    {clientEarnings?.totalReferralsPayed?.text}
                  </Typography.Text>
                </Typography.Text>
              </div>
            </Col>

            <Row></Row>

            <Col span="24" style={{ paddingTop: 5 }}></Col>
          </Card>
        </Row> */}
        <div className="site-layout-content">
          <Row>
            {/* <Card style={{ borderRadius: 20, marginLeft: 20 }}>
            <Col span="24">
              <h4>Search Filter</h4>
            </Col>
            <Row></Row>

            <Col span="24" style={{ paddingTop: 5 }}>
              <Form>
                <Form.Item label="Hostname" tooltip="Example: yuliettclub.com">
                  <AutoComplete
                    options={filterRecords}
                    style={{ width: 200 }}
                    onSearch={onSearch}
                    onSelect={onSearch}
                    placeholder="example: yuliettclub.com"
                    // value={filterHostnameText}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Card> */}
          </Row>
          <Tabs defaultActiveKey="1">
            <TabPane className="tabPane" tab="Client Reports" key="1">
              <ReportsTable
                refreshNotes={() => getNotes(setNotes)}
                notes={notes}
                clientEarnings={clientEarnings.sites}
                emailRecords={emailRecords}
                setEmailRecords={setEmailRecords}
                loading={loading}
                fromDate={fromDate}
                toDate={toDate}
                paymentSent={paymentSent}
                setPaymentSent={setPaymentSent}
                sales={sales}
              />
            </TabPane>
            <TabPane className="tabPane" tab="Sales Chart" key="2">
              <SalesChart data={sales} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
}
