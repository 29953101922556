import React, { useState, useEffect } from "react";
import { Modal, Button, Divider, Avatar, Row, Col, Input, Statistic, Tabs } from "antd";
import firebaseAPI from "../api/firebase";

import "./ClientFinancialModal.css";

const { TextArea } = Input;
const { TabPane } = Tabs;

function formatCurrency(amount) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

function updateNotes(model_id, text, setNotes) {
  firebaseAPI.updateNotes(model_id, text);
  setNotes(text);
}
async function getNotes(model_id, cb) {
  // console.log('getNotes()', model_id)
  const notes = await firebaseAPI.getNotes(model_id);
  // console.log(notes,'NOTES')
  cb(notes);
}

export default function ClientFinancialModal({
  refreshNotes,
  visible,
  setIsModalVisible,
  obj,
}) {


  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (visible) {
      getNotes(obj.id_model, setNotes);
    }
  }, [visible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    refreshNotes();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    refreshNotes();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Close
          </Button>,
        ]}
        // title="Breakdown"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div style={{ }}>
        <Tabs defaultActiveKey="2" className="financialModalTabs">
        <TabPane tab="Breakdown" key="1">
         
        
        {/* <div style={{ textAlign: "center" }}> */}
          {/* <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Gross</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.subtotal?.text}
            </Col>
          </Row> */}

          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Gross" value={obj.gross?.text}/>
            </Col>
          </Row>
          
          <Divider className="modalDivider" plain></Divider>
          {/* <Divider orientation="left" plain>Breakdown</Divider> */}

          {/* <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Memberships</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.totalEarning?.text}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Coins</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.totalCoins?.text}
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Memberships" value={obj.totalEarning?.text} />
            </Col>
            <Col span={12}>
              <Statistic title="Coins" value={obj.totalCoins?.text} />
            </Col> 
          </Row>





          <Divider className="modalDivider" plain></Divider>
          {/* <Divider plain>Costs</Divider> */}

          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Chargebacks" value={obj.chargeback?.text} />
            </Col>
            <Col span={12}>
              <Statistic title="Refunds" value={obj.totalRefunds?.text} />
            </Col> 
            <Col span={12}>
              <Statistic title="Adjustments" value={obj.adjustments?.text} />
            </Col> 
          </Row>

          {/* <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Chargebacks</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.chargeback?.text}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Refunds</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.totalRefunds?.text}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Adjustments</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.adjustments?.text}
            </Col>
          </Row> */}

          <Divider className="modalDivider" plain></Divider>
          
          {/* <Divider plain>Merchant Fees</Divider> */}


          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Processing Fee" value={obj.processFee?.text} />
            </Col>
            <Col span={12}>
              <Statistic title="Chargeback Fee" value={obj.chargebackFee?.text} />
            </Col> 
            <Col span={12}>
              <Statistic title="Refund Fee" value={obj.refoundsFee?.text} />
            </Col> 
          </Row>


          {/* <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Processing Fee</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.processFee?.text}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Chargeback Fee</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.chargebackFee?.text}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Refund Fee</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.refoundsFee?.text}
            </Col>
          </Row> */}

          <Divider className="modalDivider" plain></Divider>
          {/* <Divider plain>Payout</Divider> */}
          <Row gutter={16}>
            <Col span={12}>
              <Statistic title="Net" value={obj.supertotal?.text} />
            </Col>
            <Col span={12}>
              <Statistic title={`Client Payout (${obj.payouts?.client?.percentage?.text})`} value={formatCurrency(obj.payouts?.client?.totalPayed?.value - obj.wireFee?.value)} />
            </Col> 
            <Col span={12}>
              <Statistic title="Wire Fee" value={obj.wireFee?.text} />
            </Col> 
          </Row>

{/* 
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Net</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.supertotal?.text}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Client Payout ({obj.payouts?.client?.percentage?.text})</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.payouts?.client?.totalPayed?.text}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={12} className="incomeDescription">
              <b>Wire Fee</b>
            </Col>
            <Col span={12} className="incomeValue">
              {obj.wireFee?.text}
            </Col>
          </Row> */}

          {/* <Divider className="modalDivider" plain>Notes</Divider> */}
          {/* <Divider className="modalDivider" plain>Notes</Divider> */}
          </TabPane>
          <TabPane tab="Notes" key="2">
          <TextArea
            placeholder="Notes.."
            onChange={(text) =>
              updateNotes(obj.id_model, text.target.value, setNotes)
            }
            value={notes}
            autoSize={{ minRows: 3, maxRows: 5 }}
            className="financialModalTextArea"
          />
          </TabPane>

        </Tabs>
        </div>
        
      </Modal>
    </>
  );
}
