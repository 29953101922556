import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import chartTrendline from "chartjs-plugin-trendline";

import moment from 'moment';
import _ from 'lodash';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  chartTrendline
);

// ChartJS.register(chartTrendline);
// Bar.register(chartTrendline);

// export const options = {
//   plugins: {
//     title: {
//       display: true,
//       text: 'Sales Graph',
//     },
//   },
//   responsive: true,
//   scales: {
//     x: {
//       stacked: true,
//     },
//     y: {
//       stacked: true,
//     },
//   },
// };

export const options = {
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    
    responsive: true,
    interaction: {
      mode: 'index' ,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },


  };


// export function SalesChart({data}) {

//     let obj = {};

//     let labels = [];
//     let salesCounteriOS = [];
//     let salesCounterAndroid = [];

//     data.forEach(item => {
//         let initialDate = moment(item.initial_date).format('HH');
//         let fullDate = moment(item.initial_date).format('YYYY-MM-DD');

//         if (fullDate !== '2022-05-16') return;
//         if (!obj[initialDate]) {
//             obj[initialDate] = {sales:[]};
//         }
//         obj[initialDate].sales.push(item);
//     })

//     // obj = Object.fromEntries(
//     //   Object.entries(obj).sort(([,a],[,b]) => a-b)
//     // );
  
//     Object.keys(obj).forEach(key => {
//         labels.push(key);

//         salesCounteriOS.push(obj[key].sales.filter(item => item.device === 'ios').length);
//         salesCounterAndroid.push(obj[key].sales.filter(item => item.device === 'android').length);

//         console.log(obj[key].sales, 'aaaa')
 
      
//     })
   
//     let dataSet = [
//         {
//             label: "Sales Counter iOS",
//             data: salesCounteriOS,
//             backgroundColor: 'rgba(66, 87, 245, 0.75)',
//             borderColor: 'rgba(66, 87, 245, 1)',
//             borderWidth: 1.25,
//             // stack: 'Stack 0',
//         },
//         {
//             label: "Sales Counter Android",
//             data: salesCounterAndroid,
//             backgroundColor: 'rgba(245, 156, 66, 0.75)',
//             borderColor: 'rgba( 245, 156, 66, 1)',
//             borderWidth: 1.25,
//             // stack: 'Stack 1',
//         }
//     ]

//     console.log(dataSet,'dataSet')
//     // reverse
//     labels = labels.reverse();
//     salesCounteriOS = salesCounteriOS.reverse();
//     salesCounterAndroid = salesCounterAndroid.reverse();

//     let chartData = {
//         labels,
//         datasets: dataSet
//     }


//     return <Bar options={options} data={chartData} />;
// }
export function SalesChart({data}) {

    let obj = {};

    let labels = [];
    let salesCounteriOS = [];
    let salesCounterAndroid = [];

    data.forEach(item => {
        let initialDate = moment(item.initial_date).format('YYYY-MM-DD');
        if (!obj[initialDate]) {
            obj[initialDate] = {sales:[]};
        }
        obj[initialDate].sales.push(item);
    })

    Object.keys(obj).forEach(key => {
        labels.push(key);

        salesCounteriOS.push(obj[key].sales.filter(item => item.device === 'ios').length);
        salesCounterAndroid.push(obj[key].sales.filter(item => item.device === 'android').length);

        // console.log(obj[key].sales, 'aaaa')
 
      
    })
   
    let dataSet = [
        {
            label: "Sales Counter iOS",
            data: salesCounteriOS,
            backgroundColor: 'rgba(66, 87, 245, 0.75)',
            borderColor: 'rgba(66, 87, 245, 1)',
            borderWidth: 1.25,
            // trendlineLinear: {
            //   style: "rgba(255,105,180, .8)",
            //   lineStyle: "dotted|solid",
            //   width: 2,
            //   projection: true
            // }
            // stack: 'Stack 0',
        },
        {
            label: "Sales Counter Android",
            data: salesCounterAndroid,
            backgroundColor: 'rgba(245, 156, 66, 0.75)',
            borderColor: 'rgba( 245, 156, 66, 1)',
            borderWidth: 1.25,
            trendlineLinear: {
              style: "rgba(255,105,180, .8)",
              lineStyle: "dotted",
              width: 2,
              // projection: true
            }
            // stack: 'Stack 1',
        }
    ]

    // console.log(dataSet,'dataSet')
    // reverse
    labels = labels.reverse();
    salesCounteriOS = salesCounteriOS.reverse();
    salesCounterAndroid = salesCounterAndroid.reverse();

    let chartData = {
        labels,
        datasets: dataSet
    }


    return <Bar options={options} data={chartData} />;
}
