import { DatePicker, Space, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import moment from 'moment';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
const monthFormat = 'YYYY/MM';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const customFormat = value => `custom format: ${value.format(dateFormat)}`;



export default function DateRangePicker({setFromDate, setToDate, getEarnings}) {

    function datePickerOnChange(dates, dateStrings) {
        
        if (dateStrings.length > 0) {
          setFromDate(dateStrings[0]);
          setToDate(dateStrings[1]);
          console.log(dateStrings[0])
          console.log(dateStrings[1])
        }
      }
      
      function datePickerDisabledDates(date) {
        return new Date(date) > new Date();
      }


    return ( <Space direction="vertical" size={12}>
  
    <RangePicker
      disabledDate={datePickerDisabledDates}
      format={dateFormat}
      onCalendarChange={datePickerOnChange}
    />
    <Button type="primary" icon={<SearchOutlined />} onClick={() => getEarnings()}>
      Search
    </Button>
  </Space> )
}