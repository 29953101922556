import { useState } from "react";
import { Table, Button, Row, Col, Popconfirm, Avatar, Typography, Checkbox } from "antd";
import {
  DownloadOutlined,
  MailOutlined,
  BookOutlined,
  UserOutlined,
} from "@ant-design/icons";
import axios from "axios";
import fileDownload from "js-file-download";

import firebaseAPI from "../api/firebase";

import "./ReferralsTable.css";

import AffiliateFinancialModal from "../components/AffiliateFinancialModal";

const { Column, ColumnGroup } = Table;
const { Text } = Typography;

async function createEmailRecord(
  clientObj,
  fromDate,
  toDate,
  emailRecords,
  setEmailRecords
) {
  const { email, id, hostname, name } = clientObj;
  const affiliateId = "affiliate-" + id;

  const dateRange = {
    from: fromDate,
    to: toDate,
  };

  if (emailRecords[affiliateId] !== true) {
    firebaseAPI.createEmailRecord(affiliateId, fromDate, toDate);
    setEmailRecords({ ...emailRecords, [affiliateId]: true });
  } else {
    //TODO...
  }
  firebaseAPI.addLog("email_sent", clientObj, dateRange);
}

export default function ReferralsTable({
  notes,
  refreshNotes,
  clientEarnings,
  emailRecords,
  setEmailRecords,
  loading,
  fromDate,
  toDate,
  paymentSent,
  setPaymentSent,
}) {
  //   console.log(clientEarnings,'Referrals Table')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clientModalObj, setClientModalObj] = useState(false);

  function onChangePaymentSent(e) {
    const checked = e.target.checked;
    const { id } = e.target.obj;
    const affiliateId = "affiliate-" + id;
    console.log(checked, id);
    // if (paymentSent[affiliateId] !== true) {
    firebaseAPI.paymentSent(affiliateId, fromDate, toDate, checked);
    // }
    setPaymentSent({ ...paymentSent, [affiliateId]: checked });
  }
  // console.log(notes,'noties')
  function openModal(obj) {
    //   console.log(obj);
    setIsModalVisible(true);
    setClientModalObj(obj);
  }

  function downloadReport(clientInfo) {
    const fileName = clientInfo.name + "-" + fromDate + "-" + toDate + ".pdf";
    clientInfo.dates = {
      from: fromDate,
      to: toDate,
    };
    clientInfo.fileName = fileName;

    clientInfo.paymentDate = Date.parse(new Date(toDate))
      .next()
      .friday()
      .next()
      .thursday()
      .next()
      .thursday()
      .toString("yyyy-MM-dd");
    axios
      .post(
        "https://us-central1-financial-management-datech.cloudfunctions.net/api/create/pdf/referral",
        clientInfo,
        { responseType: "blob" }
      )
      .then((res) => {
        // axios.post('http://localhost:5001/financial-management-datech/us-central1/api/create/pdf/referral', clientInfo, {responseType: 'blob'}).then(res => {
        fileDownload(res.data, fileName);
      });
  }
  function sendReport(clientInfo) {
    const fileName = clientInfo.name + "-" + fromDate + "-" + toDate + ".pdf";
    clientInfo.dates = {
      from: fromDate,
      to: toDate,
    };
    clientInfo.fileName = fileName;

    clientInfo.paymentDate = Date.parse(new Date(toDate))
      .next()
      .friday()
      .next()
      .thursday()
      .next()
      .thursday()
      .toString("yyyy-MM-dd");

    // axios.post('http://localhost:5001/financial-management-datech/us-central1/api/send/pdf/referral', clientInfo, {responseType: 'blob'}).then(res => {

    //este es el bueno
    axios
      .post(
        "https://us-central1-financial-management-datech.cloudfunctions.net/api/send/pdf/referral",
        clientInfo,
        { responseType: "blob" }
      )
      .then((res) => {
        // fileDownload(res.data, fileName);
      });
  }

  return (
    <>
      <AffiliateFinancialModal
        refreshNotes={refreshNotes}
        obj={clientModalObj}
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

      <Table dataSource={clientEarnings} bordered={true} loading={loading} scroll={{ x: "300" }}>
        <Column
          filterDropdownVisible={true}
          title="Nickname"
          dataIndex="name"
          key="name"
          render={(data, record, index) => {
            return (
              <div key={data}>
                {" "}
                <Avatar
                  className="tableAvatar"
                  size="default"
                  icon={<UserOutlined />}
                />{" "}
                {data}
              </div>
            );
          }}
        />

        <Column
          filterDropdownVisible={true}
          title="Email"
          dataIndex="email"
          key="email"
          render={(data, record, index) => {
            return <div key={index}> {data} </div>;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Referrals"
          dataIndex="talents"
          key="talents"
          render={(data, record, index) => {
            if (data === undefined) return;
            return (
              <div key={index}>
                {data.map((referral) => {
                  return (
                    <Row>
                      <Col span={12}>
                        <div>{referral.hostname}</div>
                      </Col>
                      <Col span={6}>
                        <div>{referral.totalPayed.text}</div>
                      </Col>
                      <Col span={6}>
                        <div>{referral.percentage.text}</div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            );
          }}
        />

        <Column
          filterDropdownVisible={true}
          title="Payout"
          dataIndex="totalPayout"
          key="totalPayout"
          render={(data, record, index) => {
            return (
              <Text mark key={index}>
                {" "}
                {data.text}{" "}
              </Text>
            );
          }}
        />

        <Column
          title=""
          key="username"
          render={(obj) => (
            <Row key="buttonsTable">
              <Popconfirm
                title="Are you sure you want to send the report?"
                okText="Send"
                cancelText="Cancel"
                className="tableButton"
                onConfirm={() => {
                  sendReport(obj);
                  createEmailRecord(
                    obj,
                    fromDate,
                    toDate,
                    emailRecords,
                    setEmailRecords
                  );
                }}
              >
                <Button
                  type={
                    emailRecords["affiliate-" + obj.id] ? "primary" : "default"
                  }
                  shape="round"
                  icon={<MailOutlined />}
                  size={18}
                />
              </Popconfirm>

              <Button
                // disabled
                type={notes[obj.id] ? "primary" : "default"}
                shape="round"
                icon={<BookOutlined />}
                size={18}
                className="tableButton"
                onClick={() => openModal(obj)}
              />
              <Button
                type="default"
                shape="round"
                icon={<DownloadOutlined />}
                size={18}
                className="tableButton"
                onClick={() => downloadReport(obj)}
              />

            </Row>
          )}
        />
                      <Column
                title="Payments"
                key="username"
                render={(obj) => (
                  <Row key="buttonsTable">
                    <Checkbox
                      obj={obj}
                      checked={paymentSent['affiliate-' + obj?.id] === true ? true : false}
                      onChange={onChangePaymentSent}
                    >
                      Payment Sent
                    </Checkbox>
                  </Row>
                )}
              />
      </Table>
    </>
  );
}
