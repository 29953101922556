import { useState } from "react";
import {
  Table,
  Button,
  Row,
  Popconfirm,
  Avatar,
  Typography,
  Checkbox,
} from "antd";
import axios from "axios";
import fileDownload from "js-file-download";

import {
  DownloadOutlined,
  MailOutlined,
  BookOutlined,
  UserOutlined,
} from "@ant-design/icons";
import firebaseAPI from "../api/firebase";
import datejs from "datejs";
import moment from "moment";

import "./ReportsTable.css";

import ClientFinancialModal from "../components/ClientFinancialModal";

import clientInvoice from "../invoices/client";

const { Text } = Typography;

const { Column, ColumnGroup } = Table;

async function createEmailRecord(
  clientObj,
  fromDate,
  toDate,
  emailRecords,
  setEmailRecords
) {
  const { id_model, hostname, name } = clientObj;
  const dateRange = {
    from: fromDate,
    to: toDate,
  };

  if (emailRecords[id_model] !== true) {
    firebaseAPI.createEmailRecord(id_model, fromDate, toDate);
    setEmailRecords({ ...emailRecords, [id_model]: true });
  } else {
    //TODO...
  }
  firebaseAPI.addLog("email_sent", clientObj, dateRange);
}

function formatCurrency(amount) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export default function ReportsTable({
  notes,
  refreshNotes,
  sales,
  emailRecords,
  setEmailRecords,
  loading,
  fromDate,
  toDate,
  paymentSent,
  setPaymentSent,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clientModalObj, setClientModalObj] = useState(false);

  function getAcknowledgeStatus(subscriptionId, token) {
    console.log(subscriptionId, token);
    return axios.get(`/api/v1/android/subscription/${subscriptionId}/${token}`).then(res => {
      // return res.data.acknowledgementState;
      // return res.data;
      // console.log('acknowledgementState:', res.data.acknowledgementState)
      alert('acknowledgementState: ' +  (res.data.acknowledgementState ? 'true' : 'false'))
      // console.log(res.data)
    })
  };
  function getSubscriptionStatus(subscriptionId, token) {
    console.log(subscriptionId, token);
    return axios.get(`/api/v1/android/subscription/${subscriptionId}/${token}`).then(res => {
      // return res.data.acknowledgementState;
      // return res.data;
      // console.log('acknowledgementState:', res.data.acknowledgementState)
      console.log(res.data)
      alert(JSON.stringify(res.data, null, '\t'))
      // console.log(res.data)
    })
  };
  function acknowledgeSubscription(subscriptionId, token) {
    console.log(subscriptionId, token);
    return axios.get(`/api/v1/android/acknowledge/${subscriptionId}/${token}`).then(res => {
      // return res.data.acknowledgementState;
      // return res.data;
      console.log(res.data)
      // alert('acknowledgementState: ' +  (res.data.acknowledgementState ? 'true' : 'false'))
      // console.log(res.data)
    })
  };




  

  return (
    <>
      <ClientFinancialModal
        refreshNotes={refreshNotes}
        obj={clientModalObj}
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

      {/* active: true
device: "android"
due_date: "2022-04-03 20:13:23"
initial_date: "2022-03-03 21:13:23"
price: 4.99
sku: "gtech.igpro.sub.month"
subscription_id: "GPA.3379-9074-7976-02437"
token: "goamfleikpajjlogpokkngad.AO-J1OxuIKVlQMFu3Jp3870e8sUEVC0IQkkBr3Eg5YcuAse5CJkJU0-mOwT9S69ztGYcnV5oQ5T_dg2BA3jbrPbDZlquEqrnGHRzJPCrHF-vqJy3E4Oc5SI"
transaction_receipt: "{\"orderId\":\"GPA.3379-9074-7976-02437\",\"packageName\":\"gtech.instagram.analytics\",\"productId\":\"gtech.igpro.sub.month\",\"purchaseTime\":1646342003159,\"purchaseState\":0,\"purchaseToken\":\"goamfleikpajjlogpokkngad.AO-J1OxuIKVlQMFu3Jp3870e8sUEVC0IQkkBr3Eg5YcuAse5CJkJU0-mOwT9S69ztGYcnV5oQ5T_dg2BA3jbrPbDZlquEqrnGHRzJPCrHF-vqJy3E4Oc5SI\",\"quantity\":1,\"autoRenewing\":false,\"acknowledged\":false}"
username: "dlrdhvs" */}

      <Table
        dataSource={sales}
        bordered={true}
        loading={loading}
        pagination={false}
      >
        <Column
          filterDropdownVisible={true}
          title="Device"
          key="device"
          render={(data, record, index) => {
            return <div key={index}> {data.device}</div>;
          }}
        />
        {/* <Column
          filterDropdownVisible={true}
          title="Price"
          key="price"
          render={(data, record, index) => {
            return (
              <div key={index}>
                {" "}
                {data.price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
            );
          }}
        /> */}
        <Column
          filterDropdownVisible={true}
          title="Price"
          key="price"
          render={(data, record, index) => {
            let price = 0;
            if (data?.sku?.includes("year")) {
              price = 23.99;
            } else if (data?.sku?.includes(".month")) {
              price = 5.99;
            } else if (data?.sku?.includes("week")) {
              price = 3.99;
            } else if (data?.sku?.includes("sixmonths")) {
              price = 17.99;
            }

            return (
              <div key={index}>
                {price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
            );
          }}
        />

        <Column
          filterDropdownVisible={true}
          title="Country"
          key="country"
          render={(data, record, index) => {
            return <div key={index}> {data.country}</div>;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Username"
          key="username"
          render={(data, record, index) => {
            return <div key={index}> {data.username}</div>;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Install Date"
          key="install_date"
          render={(data, record, index) => {
            return (
              <div key={index}>
                {data.install_date ? moment(data.install_date)
                  .local().subtract(7, 'hours')
                  .format("MMMM Do h:mm a YYYY") : null}
              </div>
            );
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Initial Date"
          key="initial_date"
          render={(data, record, index) => {
            return (
              <div key={index}>
                {moment(data.initial_date)
                  .local()
                  .format("MMMM Do h:mm a YYYY")}
                  
              </div>
            );
          }}
        />

        {/* <Column
          filterDropdownVisible={true}
          title="Due Date"
          key="due_date"
          render={(data, record, index) => {
            return (
              <div key={index}>
                {moment(data.due_date)
                  .local()
                  .format("MMMM Do YYYY, h:mm:ss a")}
              </div>
            );
          }}
        /> */}
        {/* <Column
          filterDropdownVisible={true}
          title="Subscription ID"
          key="subscription_id"
          render={(data, record, index) => {
            return <div key={index}> {data.subscription_id}</div>;
          }}
        /> */}
        {/* <Column
          filterDropdownVisible={true}
          title="Token"
          key="token"
          render={(data, record, index) => {
            return <div key={index}> {(data.token)}</div>;
          }}
        /> */}
        {/* <Column
          filterDropdownVisible={true}
          title="Subscription ID"
          key="subscription_id"
          render={(data, record, index) => {
            return <div key={index}> {data.subscription_id}</div>;
          }}
        /> */}
        <Column
          filterDropdownVisible={true}
          title="SKU"
          key="sku"
          render={(data, record, index) => {
            return <div key={index}> {data.sku}</div>;
          }}
        />

        <Column
          filterDropdownVisible={true}
          title="Tracking"
          key="sas"
          render={(data, record, index) => {
            return <div key={index}> {data?.iAd_status ? 'Yes' : ''}</div>;
          }}
        />
        
        <Column
          filterDropdownVisible={true}
          title="Search Ads Campaign"
          key="sac"
          render={(data, record, index) => {
            return <div key={index}> {data?.iAd_attribution_data?.["Version3.1"]?.["iad-campaign-name"]}</div>;
          }}
        />

        <Column
          filterDropdownVisible={true}
          title="Search Ads Keyword"
          key="sak"
          render={(data, record, index) => {
            return <div key={index}> {data?.iAd_attribution_data?.["Version3.1"]?.["iad-keyword"]}</div>;
          }}
        />

        {/* <Column
          filterDropdownVisible={true}
          title="Share"
          key="share"
          render={(data, record, index) => {
            return <div key={index}> {data.share}</div>;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Acknowledged"
          key="Acknowledged"
          render={(data, record, index) => {
            return data.device === 'android' ? <button onClick={() => getAcknowledgeStatus(data.sku, data.token)} key={index}> Check Acknowledged </button> : null;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Acknowlede"
          key="Acknowlede"
          render={(data, record, index) => {
            return data.device === 'android' ? <button onClick={() => acknowledgeSubscription(data.sku, data.token)} key={index}> Acknowledge </button> : null;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Membership"
          key="membership"
          render={(data, record, index) => {
            return data.device === 'android' ? <button onClick={() => getSubscriptionStatus(data.sku, data.token)} key={index}> Membership Status </button> : null;
          }}
        /> */}
        {/* <Column
          filterDropdownVisible={true}
          title="Share"
          key="share"
          render={(data, record, index) => {
            let subscriptionStatus = getSubscriptionStatus(data.sku, data.token);
            return <div onClick={() => } key={index}> {subscriptionStatus.acknowledgementState} </div>;
          }}
        /> */}
        {/* <Column
          filterDropdownVisible={true}
          title="Hostname"
          dataIndex="hostname"
          key="hostname"
          render={(data, record, index) => {
            return (
              <div key={index}>
                {" "}
                <a href={"https://" + data} target="_blank">
                  {data}
                </a>
              </div>
            );
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Gross"
          dataIndex="subtotal"
          key="gross"
          render={(data, record, index) => {
            return <div key={index}> {data.text}</div>;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Memberships"
          dataIndex="totalEarning"
          key="totalEarning"
          render={(data, record, index) => {
            return <div key={index}> {data.text}</div>;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Coins"
          dataIndex="totalCoins"
          key="totalCoins"
          render={(data, record, index) => {
            return <div key={index}> {data.text}</div>;
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Client Payout"
          // dataIndex="payouts"
          // key="payouts"
          render={(data, record, index) => {
            return (
              <div key={index}>
                <Text mark>{ formatCurrency(data.payouts.client.totalPayed.value - data.wireFee.value) }</Text>
              </div>
            );
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Wire Fee"
          dataIndex="wireFee"
          key="wireFee"
          render={(data, record, index) => {
            return (
              <div key={index}>
                <Text mark>{data?.text}</Text>
              </div>
            );
          }}
        />
        <Column
          filterDropdownVisible={true}
          title="Payout Percentage"
          dataIndex="payouts"
          key="payouts"
          render={(data, record, index) => {
            return <div key={index}>{data.client.percentage.text}</div>;
          }}
        />

        <Column
          title="Actions"
          key="username"
          render={(obj) => (
            <Row key="buttonsTable">
              <Popconfirm
                title="Are you sure you want to send the report?"
                okText="Send"
                cancelText="Cancel"
                className="tableButton"
                onConfirm={() =>
                  {
                    sendReport(obj);
                    createEmailRecord(
                      obj,
                      fromDate,
                      toDate,
                      emailRecords,
                      setEmailRecords
                    )
                  }
                }
              >
                <Button
                  type={emailRecords[obj.id_model] ? "primary" : "default"}
                  shape="round"
                  icon={<MailOutlined />}
                  size={18}
                />
              </Popconfirm>

              <Button
                type={notes[obj.id_model] ? "primary" : "default"}
                shape="round"
                icon={<BookOutlined />}
                size={18}
                className="tableButton"
                onClick={() => openModal(obj)}
              />
              <Button
                type="default"
                shape="round"
                icon={<DownloadOutlined />}
                size={18}
                className="tableButton"
                onClick={() => downloadReport(obj)}
              />
            </Row>
          )}
        />
        <Column
          title="Payments"
          key="username"
          render={(obj) => (
            <Row key="buttonsTable">
              <Checkbox obj={obj} checked={paymentSent[obj?.id_model] ? true : false} onChange={onChangePaymentSent}>Payment Sent</Checkbox>
            </Row>
          )}
        /> */}
      </Table>
    </>
  );
}
