import fetch from "node-fetch";
import Bluebird from "bluebird";

fetch.Promise = Bluebird;

function cleanResponse(json) {
  json.referrals = json.referrals.filter((referral) => {
    return referral.talents;
  });

  return json;
}
export default class socialtechAPI {
  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  getEarnings(firstInterval, lastInterval) {
    // const URL = `https://cms.socialtechapps.com/api/v2/external/analytics/earning/byPeriod?firstInterval=${firstInterval}&lastInterval=${lastInterval}&onlyModel=2&sort=2&sortBy=DESC`;
    const URL = `https://cms.socialtechapps.com/api/v2_0_1/external/analytics/earning/byPeriod?firstInterval=${firstInterval}&lastInterval=${lastInterval}&onlyModel=2&sort=2&sortBy=DESC`;
    
    return fetch(URL, {
      headers: { Authorization: this.apiKey },
    }).then(async (res) => {
      // console.log(await res.json(),'pawww')
      return cleanResponse(await res.json())
    
    });
  }
}
