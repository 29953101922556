import React, { useState, useEffect } from "react";
import { Modal, Button, Divider, Avatar, Row, Col, Input, Statistic, Tabs } from "antd";
import firebaseAPI from "../api/firebase";

import "./ClientFinancialModal.css";

const { TextArea } = Input;
const { TabPane } = Tabs;


function updateNotes(id, text, setNotes) {
  firebaseAPI.updateNotes(id, text, false);
  setNotes(text);
}
async function getNotes(id, cb) {
  const notes = await firebaseAPI.getNotes(id, false);
  // console.log(notes,'mynotes',id)
  cb(notes);
}

export default function AffiliateFinancialModal({
  refreshNotes,
  visible,
  setIsModalVisible,
  obj,
}) {


  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (visible) {
      getNotes(obj.id, setNotes);
    }
  }, [visible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    refreshNotes();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    refreshNotes();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Close
          </Button>,
        ]}
        // title="Breakdown"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div style={{ }}>
        <Tabs defaultActiveKey="2" className="financialModalTabs">
        {/* <TabPane tab="Breakdown" key="1">
         
        
 

          <Row gutter={16}>
            <Col span={12}>

              <Statistic title="Gross" value={'Test'}/>
            </Col>
          </Row>
 
          </TabPane> */}
          <TabPane tab="Notes" key="2">
          <TextArea
            placeholder="Notes.."
            onChange={(text) =>
              updateNotes(obj.id, text.target.value, setNotes)
            }
            value={notes}
            autoSize={{ minRows: 3, maxRows: 5 }}
            className="financialModalTextArea"
          />
          </TabPane>

        </Tabs>
        </div>
        
      </Modal>
    </>
  );
}
